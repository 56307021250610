.modal-backdrop{
    z-index:998;
    background-color:rgba(0,0,0,0.6);
}

#content-modal{
    top:50px;
    z-index:999;
    background-color:rgba(0,0,0,0.6);
}

#content-modal .modal-dialog{
    width:1080px;    
    margin-top:60px;
    border:none;
}

#content-modal .modal-header, 
#content-modal .modal-footer{
    padding:0px;
    border:none;
}

#content-modal .modal-content{
    border-radius: 0px;
    padding: 16px;
    width:1080px;
    box-shadow:none;
}

#content-modal .modal-body{
    padding:0px;
}

#content-modal .modal-header{
    padding:42px 0px 0px 58px;
}


/* Base Modals */

.modal-dialog{
    overflow-x:hidden;
    background-color:rgba(255,255,255,1);
    padding-top:50px;
    border-radius: 0px;
    border:1px solid #95989A;
}

.naked .modal-dialog{
    padding-top:0px;
}

.modal-toolbar{
    position: absolute;
    left:0px;
    top:0px;
    width:100%;
    height:50px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}

.modal-toolbar .back{
    width:36px;
    height:36px;
    border-radius: 18px;
    background-color: #CCCCCC;
    color:#fff;
    font-size:14px;
    opacity:1;
    outline:none;
    margin:10px 0px 0px 10px;
    float:none; 
    border:none;
}

.modal-toolbar .modal-breadcrumb{
    font-size:14px;
    margin-top:10px;
}

.modal button.close{
    width:15px;
    height:15px;
    color:#fff;
    font-size:14px;
    opacity:1;
    outline:none;
    margin:0px 15px 0px 0px;
    float:none;
    background-image:url('../../../public/assets/images/icons/close.svg');
}

.naked button.close{
    position: absolute;
    right:20px;
    margin:0px;
    top:20px;
    z-index:2;
    background-image:url('../../../public/assets/images/icons/close-white.svg');
}

.modal-content{
    float:left;
    left:0px;
    overflow-y:auto;
    max-height:600px;
    border-radius: 0px;
    border:none;
    position:relative;  
}

.modal-content.pull{
    left:-1080px;
}

.modal-backdrop{
    z-index:998;
  background-color:rgba(255,255,255,1);
  opacity: 0.3;
  -webkit-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
}

.modal-backdrop.pre-display{
    opacity: 0
}

.modal-backdrop.hide-backdrop{
    opacity:0;
}

.modal .modal-animation-wrapper{
    position: relative;
    left:0px;
    display: block;
    width:100000px;
}

.modal-animation-wrapper.pre-pull{  
    left:-1080px;
}

.modal-animation-wrapper.push{
    -webkit-transition: left 0.2s ease-in;
    transition: left 0.2s ease-in;  
    left:-1080px;
}

.modal-animation-wrapper.pull{
    -webkit-transition: left 0.2s ease-in;
    transition: left 0.2s ease-in;  
}

.modal.pre-display{
    display:block;
}

.modal.magic .modal-dialog{
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;

    -webkit-transform: scale(1);
    transform: scale(1);
}

.modal.pre-display.magic .modal-dialog{
    -webkit-transform: scale(0);
    transform: scale(0);
}

.modal.post-display.magic .modal-dialog{
    -webkit-transform: scale(0);
    transform: scale(0);
}

.modal .statementstable{
  width:100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  thead{
  }
  th{
    padding-top:11px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;
    margin-bottom: 15px;
    font-weight: 300;

    &:last-child{
      text-align: right;
    }
  }
  td{
    padding:5px 0px;

    &:last-child{
      text-align: right;
    }
  }
}
