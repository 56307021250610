@import '../libs/theme/css/bootstrap.min.css';
@import '../libs/theme/css/icons/icomoon/styles.css';
@import '../libs/theme/css/core.css';
@import '../libs/theme/css/components.css';
@import '../libs/theme/css/colors.css';
@import './modals.css';

body {
  background: #eee;
}

.navbar-nav > .dropdown-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-size: 30px;
}

div.content .search-panel,
div.content .list-panel,
div.content .command-panel,
div.content .form-panel {
  display: none;
}

div.content .index-container[data-mode='search'] .search-panel,
div.content .index-container[data-mode='search'] .list-panel {
  display: block;
}

div.content .index-container[data-mode='command'] .list-panel,
div.content .index-container[data-mode='command'] .command-panel {
  display: block;
}

div.content .index-container[data-mode='form'] .command-panel,
div.content .index-container[data-mode='form'] .form-panel {
  display: block;
}

div.content a.toggle-mode {
  display: none;
}

div.content .index-container[data-mode='command'] .list-panel .panel-heading .toggle-mode {
  display: inline;
}

div.content .index-container[data-mode='form'] .command-panel .panel-heading .toggle-mode {
  display: inline;
}

div.content .index-container[data-mode='command'] #list-panel .panel-footer {
  display: none;
}

#result-panel table tr.selected .add {
  display: none;
}

#command-form-panel .panel-footer {
  display: none;
}
#list-panel .panel-footer {
  display: none;
}

#command-form-panel.active .panel-footer {
  display: block;
}
#list-panel.active .panel-footer {
  display: block;
}

.modal .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
}

.add,
.add_all,
.remove,
.remove_all {
  font-size: 16px;
}

#result-list th,
#result-list td,
#list th,
#list td {
  line-height: 20px;
}

.autocomplete-suggestions {
  background-color: #fff;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.autocomplete-suggestions .autocomplete-suggestion {
  cursor: pointer;
  padding: 7px 12px;
  border-bottom: 1px solid #ddd;
}

.autocomplete-suggestions .autocomplete-suggestion .title {
}

.autocomplete-suggestions .autocomplete-suggestion .meta {
  color: #bbb;
}

.autocomplete-suggestions .autocomplete-suggestion:last-child {
  border-bottom: 0;
}

.autocomplete-suggestions .autocomplete-selected {
  background: #eee;
}

.modal-header img {
  margin-right: 16px;
}

.modal-header .uuid,
.modal-header .slug {
  color: #ccc;
  font-size: 12px;
}

.lp--organisation-autocomplete {
  color: #999;
}

.lp--organisation-autocomplete.selected {
  color: #000;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #bbb;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px 20px;
}
table.dataTable.no-footer {
  border-bottom: none;
}
.dataTables_wrapper .dataTables_filter {
  margin-right: 6px;
  display: none;
}
.dataTables_wrapper .dataTables_filter.show {
  display: block;
}
.dataTables_wrapper .dataTables_info {
  padding: 10px 20px;
  display: none;
}
.dataTables_wrapper .dataTables_info.show {
  display: block;
}

.dataTables_filterButton,
.dataTables_exportButton {
  float: right;
  font-family: icomoon;
  font-size: 12px;
  color: #999;
  margin: 0 6px 6px 0;
  cursor: pointer;
}
.dataTables_filterButton.hide {
  display: none;
}

@media (max-width: 1024px) {
  #content-modal {
    top: 0;
  }
  #content-modal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  #content-modal .modal-content {
    width: 100%;
    padding: 8px;
    max-height: 100%;
  }
  .modal .modal-animation-wrapper {
    width: 100%;
  }
  .modal-header > .col-md-6:not(:first-child) {
    clear: both;
    padding-top: 8px;
  }
}
